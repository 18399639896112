<template>
  <div class="form-channel relative h-full">
    <a-form
      ref="formModal"
      :model="formState"
      label-align="left"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
      @finish="onFinish"
      class="min-h-[632px] h-full flex flex-col justify-between"
    >
      <div>
        <div>
          <a-form-item name="name">
            <span class="text-sm text-ems-gray500 block mb-1">{{
              t('channel.name')
            }}</span>
            <cds-input
              :disabled="action === ACTION.VIEW"
              class="w-100 ant-input-sm"
              v-model:value="formState.name"
              :show-count="false"
              size="default"
              :placeholder="t('channel.name-placeholder')"
            />
          </a-form-item>
          <a-form-item name="desc">
            <span class="text-sm text-ems-gray500 block mb-1">{{
              t('channel.desc')
            }}</span>
            <cds-input
              :disabled="action === ACTION.VIEW"
              class="w-100 ant-input-sm"
              v-model:value="formState.desc"
              :show-count="false"
              size="default"
              :placeholder="t('channel.desc-placeholder')"
            />
          </a-form-item>

          <a-form-item name="status">
            <span class="text-sm text-ems-gray500 block mb-1">{{
              t('channel.status')
            }}</span>
            <cds-select
              :options="STATUS"
              :placeholder="$t('channel.status')"
              v-model:value="formState.status"
              :disabled="action === ACTION.VIEW"
              class="ant-input-sm h-[38px]"
            />
          </a-form-item>
        </div>
        <div class="h-[1px] w-full !my-5 bg-ems-gray700"></div>
        <div class="flex justify-between !mb-5 items-center">
          <div>
            <span class="font-semibold text-base text-ems-gray200">{{
              t('channel.add-member')
            }}</span>
            <span
              class="text-sm text-ems-gray400 block mt-1"
              v-if="isAddingMember"
              >{{ listUsers ? listUsers.length : 0 }}
              {{ ' ' + t('channel.members') }}</span
            >
          </div>
          <cds-select
            :options="listGroup"
            :placeholder="$t('channel.all')"
            v-model:value="formState.group"
            :disabled="action === ACTION.VIEW"
            class="!w-[180px] h-[38px] channel-select-group"
            mode="multiple"
            max-tag-count="responsive"
            :maxTagTextLength="5"
            v-if="!isAddingMember"
            @change="handleChangeGroup"
            :showSearch="true"
          />
          <cds-button
            size="small"
            type="primary"
            @click="handleClickAddMember"
            v-if="isAddingMember && action !== ACTION.VIEW"
          >
            {{ t('common.add') }}
          </cds-button>
        </div>
        <a-form-item name="users" v-if="!isAddingMember">
          <a-select
            :options="listUserSearch"
            :label-prop="'label'"
            :value-prop="'value'"
            v-model:value="formState.users"
            :placeholder="t('common.search')"
            class="ant-input-sm channel-select-user relative"
            :class="formState.users.length > 0 ? 'has-member' : 'no-member'"
            mode="multiple"
            :disabled="action === ACTION.VIEW"
            @search="fetchUser"
            show-search
            option-label-prop="name"
            size="small"
            :dropdownClassName="'channel-select-user-dropdown'"
            :maxTagCount="2"
            :filter-option="false"
            @select="handleSelectUser"
            @deselect="handleDeSelectUser"
          >
            <template #option="{ value: val, name, img, email }">
              <div class="flex gap-[20px] rounded-[8px] px-3">
                <div
                  class="h-[52px] w-[52px] rounded-full overflow-hidden border flex items-center justify-center"
                >
                  <img
                    :src="img"
                    :alt="img"
                    class="w-full h-auto flex-shrink-0"
                  />
                </div>
                <div class="flex flex-col gap-1">
                  <span class="text-base text-ems-gray100 font-semibold">{{
                    name
                  }}</span>
                  <span class="text-[12px] text-ems-gray300">{{ email }}</span>
                </div>
              </div>
            </template>
            <template
              #tagRender="{
                value: value,
                img,
                label,
                closable,
                onClose,
                option,
              }"
            >
              <a-tag
                :closable="closable"
                style="margin-right: 3px"
                @close="onClose"
                class="flex items-center bg-ems-gray600 border-none py-1 pl-1 pr-2 rounded-[20px] mb-1 w-fit"
              >
                <div
                  class="h-[20px] w-[20px] rounded-full overflow-hidden border flex items-center justify-center"
                >
                  <img
                    :src="option ? option.img : label"
                    :alt="label"
                    class="h-auto w-full"
                  />
                </div>
                <span class="text-white text-[12px] font-normal">{{
                  value
                }}</span>
              </a-tag>
            </template>
            <template #maxTagPlaceholder="omittedValues">
              <span class="text-white text-[12px] font-normal"
                >+ {{ omittedValues.length }} ...</span
              >
            </template>
          </a-select>
          <cds-feather-icons
            type="chevron-down"
            class="absolute top-0 right-4 h-full flex items-center text-ems-gray400"
          />
          <cds-feather-icons
            v-if="formState.users.length === 0"
            type="search"
            class="absolute top-0 left-4 h-full flex items-center text-ems-gray400"
          />
        </a-form-item>

        <div
          v-if="isAddingMember"
          class="overflow-y-auto max-h-[300px] hidden-scroll"
        >
          <div
            class="flex flex justify-between items-center rounded-[8px] !px-2 py-2 hover:bg-ems-tag1"
            v-for="(item, idx) in listUsers"
            :key="idx"
            @mouseenter="idHover = idx"
            @mouseleave="idHover = ''"
          >
            <div class="flex space-x-2">
              <div
                class="h-[52px] w-[52px] rounded-full overflow-hidden border flex items-center justify-center flex-shrink-0"
              >
                <img
                  :src="item.img"
                  :alt="item.img"
                  class="w-full h-auto flex-shrink-0"
                />
              </div>
              <div class="flex flex-col gap-1">
                <span class="text-base text-ems-gray100 font-semibold">{{
                  item.name
                }}</span>
                <span class="text-[12px] text-ems-gray300">{{
                  item.state ? t('channel.online') : t('channel.offline')
                }}</span>
              </div>
            </div>
            <img v-if="idHover===idx" alt="delete" src="@/static/img/icon/delete-user.svg" class="cursor-pointer" @click="handleDetele(item)"/>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-center">
        <div class="flex !gap-5 w-100" v-if="!isAddingMember">
          <a-button
            size="small"
            type="primary"
            class="w-100 text-ems-white py-[9px] h-full border-none"
            :disabled="!formState.users || formState.users.length === 0"
            @click="handleAddUser"
          >
            {{ t('common.add') }}
          </a-button>
          <a-button
            size="small"
            class="w-100 !bg-ems-gray700 border-none text-ems-white py-[9px] h-full"
            @click="cancelAddMember"
          >
            {{ t('channel.cancel') }}
          </a-button>
        </div>
        <ClipButton
          v-if="action !== ACTION.VIEW && isAddingMember"
          type="primary"
          size="small"
          style="height: fit-content"
          class="w-[200px]"
        >
          <span class="text-sm text-ems-white whitespace-nowrap">{{
            action === ACTION.CREATE ? $t('channel.create') : $t('channel.edit')
          }}</span>
        </ClipButton>
      </div>
    </a-form>
  </div>
</template>
<script setup>
import { computed, ref, defineEmits, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import Tag from '@/components/tags/Tag';
import { debounce } from 'lodash';
import { useForm } from 'ant-design-vue/es/form';
import { maxlength, patternRule, required } from '@/util/validation';
import { STATUS, STATUS_CHANNEL, ACTION } from '@/util/common-constant';

const { t } = useI18n();
const action = computed(() => state.channelManagement.action);
const listGroup = computed(() => state.channelManagement.listGroup);
const listUserSearch = computed(() => state.channelManagement.listUserInGroup);
const listUserHaveGroup = computed(() => state.channelManagement.users);
const isAddingMember = computed(() => state.channelManagement.isAddingMember);
const idUser = computed(() => state.auth.idUser);
const idHover = ref('')
const listUsers = ref([]);
const { state, dispatch } = useStore();
const formState = computed(() => state.channelManagement.channel);
const emit = defineEmits(['saveSuccess']);

watch(listUserHaveGroup, (newVal, oldVal) => {
  newVal.map((item) => {
    listUsers.value.push({
      id: item.id,
      img: item.img,
      name: item.name,
      phone: item.phone,
      email: item.email,
      state: item.state,
      label: item.img,
      value: item.name,
    });
  });
});
const handleSelectUser = (user, info) => {
  listUsers.value.push(info);
  formState.value.users = listUsers.value;
};
const handleDeSelectUser = (user, info) => {
  listUsers.value = listUsers.value.filter((item) => item.name !== user);
  formState.value.users = listUsers.value;
};
const handleDetele = (user)=>{
  listUsers.value = listUsers.value.filter((item) => item.name !== user.name);
  formState.value.users = listUsers.value;
}
const handleClickAddMember = () => {
  dispatch('channelManagement/preChangeAddMember', false);
};
const cancelAddMember = () => {
  listUsers.value=[]
  formState.value.users =[]
  dispatch('channelManagement/preChangeAddMember', true);
};
const handleAddUser = () => {
  dispatch('channelManagement/preChangeAddMember', true);
};
const handleChangeGroup = (idGroup) => {
  let listId = idGroup.join(',');
  dispatch('channelManagement/getListUserByGroup', {
    username: '',
    roleId: `${listId}`,
    size: 100,
  });
};
const rules = ref({
  name: [maxlength(50, t('channel.name')), required(t('channel.name'))],
  code: [maxlength(200, t('channel.desc'))],
  status: [],
  group: [],
  users: [],
});
const { validate } = useForm(formState.value, rules);
const compareArrays = (oldArray, newArray) => {
  const addedItems = newArray.filter((item) => !oldArray.includes(item));
  const removedItems = oldArray.filter((item) => !newArray.includes(item));
  return {
    addedItems,
    removedItems,
  };
};
const onFinish = () => {
  if (action.value === ACTION.CREATE) {
    let data = {
      name: formState.value.name,
      description: formState.value.desc ? formState.value.desc : '',
      listParticipants: listUsers.value.map((item) => item.id),
      active: formState.value.status === STATUS_CHANNEL.ACTIVE ? true : false,
    };
    dispatch('channelManagement/create', data).then(() => {
      emit('saveSuccess');
    });
  } else if (action.value === ACTION.EDIT) {
    let data = {
      channelId: formState.value.id,
      name: formState.value.name,
      description: formState.value.desc ? formState.value.desc : '',
      active: formState.value.status === STATUS_CHANNEL.ACTIVE ? true : false,
    };
    const listUsersName = listUsers.value.map((item) => item.id);
    const listUsersNameOld = listUserHaveGroup.value.map((item) => item.id);
    const result = compareArrays(listUsersNameOld, listUsersName);
    const { addedItems, removedItems } = result;
    dispatch('channelManagement/update', data).then(() => {
      emit('saveSuccess');
    });
    if (addedItems.length > 0) {
      let listAddUser = {
        channelId: formState.value.id,
        listParticipants: addedItems.filter((item) => item !== idUser.value),
      };
      dispatch('channelManagement/addUser', listAddUser).then();
    }
    if (removedItems.length > 0) {
      let listDeleteUser = {
        channelId: formState.value.id,
        listParticipants: removedItems.filter((item) => item !== idUser.value),
      };
      dispatch('channelManagement/deleteUser', listDeleteUser).then();
    }
  }
};
const fetchUser = debounce(function (value) {
  dispatch('channelManagement/getListUserByGroup', {
    username: value,
    roleId: formState.value.group,
  });
}, 500);
</script>
<style lang="scss">
.form-channel .ant-input-sm {
  border: none !important;
}
.form-channel .ant-select {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-style: none;
  width: 100%;
}
.form-channel .ant-select-selector {
  background-color: #2B2A3A;
  border: none !important;
  border-radius: 0;
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.form-channel .ant-select-selector .ant-select-selection-search {
  background-color: #2B2A3A;
}
.form-channel .ant-select-selector .ant-select-selection-placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #706F80 !important;
}
.form-channel .ant-select-selector .ant-select-selection-item {
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
.form-channel .ant-input {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 5px;
  padding-bottom: 5px;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
  border: none !important;
}
.form-channel .ant-input::placeholder {
  --tw-text-opacity: 1;
  color: rgba(112, 111, 128, var(--tw-text-opacity));
}
.form-channel .channel-select-group .ant-select-selection-item, .form-channel .channel-select-user .ant-select-selection-item {
  margin-top: 12.5px;
  margin-left: 2px;
}
.form-channel .channel-select-group, .form-channel .channel-select-user {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
  border-radius: 8px;
}
.form-channel .channel-select-group .ant-select-selector, .form-channel .channel-select-user .ant-select-selector {
  background-color: #2B2A3A !important;
  border-radius: 8px !important;
}
.form-channel .channel-select-group .ant-select-selector .ant-select-selection-search, .form-channel .channel-select-user .ant-select-selector .ant-select-selection-search {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
}
.form-channel .channel-select-user {
  padding: 0;
}
.form-channel .channel-select-user .ant-select-selector {
  height: fit-content !important;
  padding: 6px 8px !important;
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow {
  top: 6px;
  padding-right: 22px;
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-tag {
  position: relative;
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-tag:hover .ant-tag-close-icon {
  display: block;
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-tag .ant-tag-close-icon {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: none;
  margin: 0px;
  padding: 5px;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(68, 68, 89, var(--tw-text-opacity));
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search, .form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search-mirror, .form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-select-selection-search-input {
  height: 26px;
  color: white;
}
.form-channel .channel-select-user .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item-rest .ant-select-selection-item {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 89, var(--tw-bg-opacity));
  border-radius: 20px;
  border-style: none;
  height: 28px;
  margin: 0px;
  margin-bottom: 0.25rem;
}
.form-channel .no-member .ant-select-selection-search {
  position: relative;
  left: 33px;
}
.form-channel .no-member .ant-select-selection-placeholder {
  left: 40px;
}
.channel-select-user-dropdown {
  padding: 12px 0 !important;
  box-shadow: none !important;
  --tw-bg-opacity: 1;
  background-color: rgba(24, 24, 36, var(--tw-bg-opacity));
}
.channel-select-user-dropdown .ant-select-item-option {
  padding: 8px !important;
  border-radius: 8px;
}
.channel-select-user-dropdown .ant-select-item-option:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(43, 42, 58, var(--tw-bg-opacity));
}
.channel-select-user-dropdown .ant-select-item-option .ant-select-item-option-state {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.channel-select-user-dropdown .ant-select-item-option .ant-select-item-option-state .anticon-check {
  --tw-bg-opacity: 1;
  background-color: rgba(112, 111, 128, var(--tw-bg-opacity));
  border-radius: 9999px;
  padding: 0.25rem;
  --tw-text-opacity: 1;
  color: rgba(233, 233, 233, var(--tw-text-opacity));
}
</style>
